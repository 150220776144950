import request from 'umi-request';
import config from "./config";

interface RequestInfo<T> {
    path: string
    method?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
    headers?: HeadersInit
    data?: T
}

const requestHelp = async (requestInfo: RequestInfo<any>) => {
    const response: Response = await request.post(`${config.baseURL}${requestInfo.path}`, {
        headers: {
            "Content-Type": "application/json",
            'Accept': 'text/plain,application/json',
            ...requestInfo.headers
        },
        parseResponse: false,
        credentials: 'include',
        data: requestInfo.data,
    });
    if (response.ok) {
        return response.json();
    } else if (response.status >= 500) {
        throw new Error(`${response.status}:${response.statusText}`);
    } else {
        throw new Error(`${await response.text()}`);
    }
}
export default requestHelp;